@import '../mm-period-selector';

.mm-ps-header-type:last-child {
  margin-right: 0 !important;
}

.mm-ps-header {
  border-bottom: solid 1px #edeeef;
  padding: 15px;
  .mm-ps-header-type {
    text-transform: capitalize;
    padding: 5px 10px;
    border: solid 1px $brand;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
  }
  .active {
    background-color: $brand;
    color: white;
  }
}
