.business-selector {
  width: 400px;

  @media (max-width: 767px) {
    width: 250px;
  }

  #react-select-3-input {
    border: none !important;
  }
}
