@import "variables";

/* Global Font Settings */
body {
  text-rendering: optimizeLegibility;
  font-family: Poppins, Helvetica, "sans-serif" !important;
  -webkit-font-smoothing: antialiased;
}
body,
input,
button  {
  -webkit-font-smoothing: antialiased;
}

i {
  color: $gray2;
  transition: all .3s !important;
}

/*** Util Text Classes ***/
.text-subtext {
  color: $gray;
}

.text-inactive {
  color: rgba(100, 108, 154, 0.31) !important;
}

.text-wrap-break {
  word-wrap: break-word;
}

.text-brand {
  color: $brand !important;
}

.hover-brand {
  &:hover {
    color: $brand !important;
    cursor: pointer;
  }
}

.text-drive {
  color: $drive !important;
}

.text-italics {
  font-style: italic;
}

.text-bold {
  font-weight: 600 !important;
}

.text-inherit {
  font-size: inherit !important;
}

.text-success {
  color: $success !important;
}

.text-underline {
  text-decoration: underline;
}

.text-hover {
  &:hover {
    text-decoration: underline;
    color: $brand;
    cursor: pointer;
    transition: all 0.3s;
  }
}
