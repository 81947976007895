.preview-section {
  height: inherit;

  @media (max-width: 1150px) {
    height: inherit;
  }

  @media (max-width: 1459px) {
    height: 550px;
  }

  @media (min-width: 1460px) {
    height: 550px;
  }

  @media (min-width: 1550px) {
    height: 660px;
  }

  @media (min-width: 1700px) {
    height: 620px;
  }

  @media (min-width: 1800px) {
    height: 660px;
  }

  @media (min-width: 2000px) {
    height: 700px;
  }
}
