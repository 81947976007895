@import '../mm-period-selector';

.mm-ps-options {
  width: 100% !important;
  min-width: 390px;
  max-height: 400px;
  overflow: scroll;

  @media (max-width: 767px) {
    min-width: 100% !important;
    max-height: 100vh;
  }

  .mm-ps-options-header {
    font-size: 15px;
    margin-top: 15px;
    color: $default;
    i {
      font-size: 30px;
      cursor: pointer;
    }
    i:hover,
    .cat:hover {
      color: $brand !important;
    }
  }

  .mm-ps-option {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    width: 40px;
    cursor: pointer;
    text-align: center;
    margin-top: 1px;
    margin-bottom: 1px;
    border: 1px solid transparent;
    transition: all 0.2s;
  }
  .mm-ps-out-of-bounds-before,
  .mm-ps-out-of-bounds-after {
    background-color: rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.5);
  }
  .mm-ps-option:hover {
    border: 1px solid black;
  }
  .mm-ps-range {
    background-color: $brand;
    opacity: 0.5;
    color: white;
  }
  .mm-ps-edge {
    background-color: $brand;
    color: white;
    opacity: 1;
  }
  .mm-ps-edge-start {
    border-radius: 4px 0 0 4px;
  }
  .mm-ps-edge-end {
    border-radius: 0 4px 4px 0;
  }
  .mm-ps-option-day {
    cursor: pointer;
  }
  .mm-ps-option-day-hover {
    background-color: $brand;
    opacity: 0.3;
    color: white;
  }
}
