@import './variables';

/* Backgrounds */
.bg-success {
  background-color: $success !important;
}

.bg-brand {
  background-color: $brand !important;
}

.bg-gray {
  background-color: #eff0f5;
}

$fadeOpacity: 0.2;

.faded {
  &.bg-success {
    background-color: rgba($success, $fadeOpacity) !important;
  }
  &.bg-warning {
    background-color: rgba($warning, $fadeOpacity) !important;
  }
  &.bg-danger {
    background-color: rgba($danger, $fadeOpacity) !important;
  }
  &.bg-drive {
    background-color: rgba($drive, $fadeOpacity) !important;
  }
  &.bg-brand {
    background-color: rgba($brand, $fadeOpacity) !important;
  }
}

/* Borders */
.br-default {
  border-right: $border-color;
  @media (max-width: 767px) {
    border-right: none !important;
  }
}

.bb-default {
  border-bottom: $border-color;
}

.bt-default {
  border-top: $border-color;
}

.bl-default {
  border-left: $border-color;
  @media (max-width: 767px) {
    border-left: none !important;
  }
}
