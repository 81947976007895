@import "./variables";

// Breakpoints for dynamic media queries
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px,
  x-large: 1440px,
);

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    }
    @else {
      // If $fs-font-size is a key that exists in $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  }
  @else {
    font-size: $fs-font-size;
  }
}

// Dynamic <p> tag Font Sizes and line-height by breakpoint
$p-font-sizes: (
  null  : (13px, 1.3),
  small : (13px, 1.35),
  medium: (14px, 1.4),
  large : (14px, 1.4),
  x-large: (14px, 1.4),
);

// generate dynamic paragraph sizes
p {
  word-break: break-word;
  @include font-size($p-font-sizes);
}


// Dynamic Scaled Heading Font Sizes
@function create_heading_map($headingMap, $scale) {
  @return (
    null  : map-get($headingMap, null) - $scale,
    480px : map-get($headingMap, 480px) - $scale,
    640px : map-get($headingMap, 640px) - $scale,
    1024px: map-get($headingMap, 1024px) - $scale
  );
}

// H1 Sizes by breakpoint
$heading-font-sizes: (
  null  : 27px,
  480px : 29px,
  640px : 31px,
  1024px: 34px
);

// Create Sass Maps based on H1 and scaled down proportionally
$h1-font-sizes: create_heading_map($heading-font-sizes, 0);
$h2-font-sizes: create_heading_map($heading-font-sizes, 5);
$h3-font-sizes: create_heading_map($heading-font-sizes, 9);
$h4-font-sizes: create_heading_map($heading-font-sizes, 11);
$h5-font-sizes: create_heading_map($heading-font-sizes, 15);
$h6-font-sizes: create_heading_map($heading-font-sizes, 17);

// generate dynamic heading sizes
h1 {@include font-size($h1-font-sizes);}
h2 {@include font-size($h2-font-sizes);}
h3 {@include font-size($h3-font-sizes);}
h4 {@include font-size($h4-font-sizes);}
h5 {@include font-size($h5-font-sizes);}
h6 {@include font-size($h6-font-sizes);}
