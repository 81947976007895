@import '../../../public/sass/variables';

#progressbar {
  background-color: white;
  border: $border-color;
  border-radius: 4px;

  .progress-steps {
    display: flex;
    align-items: stretch; /* Default */
    justify-content: space-between;
    width: 100%;
  }

  .mobile-step {
    flex: auto;
    width: fit-content;
    margin-bottom: 0;
    border-right: $border-color;

    &:last-child {
      border-right: none !important;
    }

    i {
      font-size: 20px;
    }
  }
}
