.single-preview {
  .ad-preview {
    margin: 0 auto;

    @media (max-width: 1275px) {
      transform: scale(0.8, 0.8) translate(-10%, -15%) !important;
    }

    // macbook?
    //@media (max-width: 1459px) {
    //  transform: scale(0.75, 0.75) translate(-22%, -3%);
    //}

    @media (max-width: 1459px) {
      transform: scale(0.55, 0.55) translate(-38%, -13%);
    }

    @media (min-width: 1460px) {
      transform: scale(0.65, 0.65) translate(-25%, -13%);
    }

    @media (min-width: 1550px) {
      transform: scale(0.7, 0.7) translate(-15%, -4%);
    }

    @media (min-width: 1700px) {
      transform: scale(0.75, 0.75) translate(-10%, 0%);
    }

    @media (min-width: 1800px) {
      transform: scale(0.85, 0.85) translate(-3%, 0%);
    }

    @media (min-width: 2000px) {
      transform: scale(0.95, 0.95) translate(3%, 1%);
    }
  }
}
