.bulk-upload-list {
  table {
    overflow: inherit !important;

    tr td:last-child,
    tr td:nth-last-child(2) {
      overflow: visible !important;
    }
  }
}
