@import '../../../../../public/sass/variables';

.progress-card {
  h5 {
    color: inherit;
    line-height: 1.25rem;
  }
  .progress-step {
    color: $gray2;
    border: 2px solid;
    border-color: inherit;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 27px;
    font-weight: 600;
  }

  &.active {
    color: $gray2;
    border-color: $gray2 !important;
  }

  &.inactive {
    color: rgba(100, 108, 154, 0.31);
    border-color: rgba(100, 108, 154, 0.31) !important;
    box-shadow: none !important;
  }

  &.complete {
    cursor: pointer;
    border-color: $success !important;

    i.complete-check {
      color: $success;
      font-size: 20px;
    }
  }

  hr {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: $border-color;
  }
}
