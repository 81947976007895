// Notes:
// 1. if iFrame is responsive the inner content may NOT
// 2. to maintain aspect ratio of inner iFrame content may need to use
// transform property
// 3. Facebook documentation for iFrame:
// - Link: https://developers.facebook.com/docs/plugins/page-plugin/#resizing
// - Width: The pixel width of the plugin. Min. is 180 & Max. is 500 Default: 340px
// - Height: height of the plugin. Min. is 70 Default: 500

$phoneBottom: #5b5858;
$phoneShadow: #666;
$phoneFrame: black;

.ad-preview {
  .phone-container {
    width: fit-content;
  }

  .phone {
    border: 12px solid $phoneFrame;
    border-radius: 40px;
    overflow: hidden;
    transition: all 0.5s ease;
    width: 340px;
    height: 630px;
    background: white;
    margin-bottom: 30px;
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    box-shadow: 0px 3px 0 $phoneBottom, 0px 4px 0 $phoneBottom, 0px 5px 0 $phoneBottom,
      0px 7px 0 $phoneBottom, 0px 10px 20px $phoneShadow;

    iframe,
    .phone-children {
      border: 0;
      width: 100%;
      height: 100%;
      margin-top: 20px;
      -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }

    // iPhone camera block
    &::before {
      content: ' ';
      display: block;
      background: $phoneFrame;
      height: 20px;
      width: 50%;
      left: 50%;
      transform: translate(-50%, 0%);
      border-radius: 0 0 12px 12px;
      border-bottom: 1px solid silver;
      position: absolute;
      top: -1px;
      z-index: 200;
    }

    // iPhone swipe bar
    &::after {
      content: ' ';
      display: block;
      background: $phoneFrame;
      height: 8px;
      width: 150px;
      left: 50%;
      transform: translate(-50%, 0%);
      border-radius: 20px;
      position: absolute;
      bottom: 9px;
      z-index: 200;
    }

    // Responsive sizing
    @media (max-width: 900px) {
      transform: scale(0.8, 0.8) translate(0%, -10%);
    }

    @media (max-width: 700px) {
      transform: scale(0.6, 0.6) translate(0%, -30%);
    }

    @media (max-width: 500px) {
      transform: scale(0.5, 0.5) translate(0%, -50%);
    }

    /* ----------- Retina Screens ----------- */
    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
      transform: scale(0.8, 0.8) translate(-9%, -12%);
    }
  }
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
