@import "./variables";

/* Structure */
.h-100 {
  min-height: 100%;
  height: auto !important; /* cross-browser */
  height: 100%; /* cross-browser */
}

.w-33 {
  width: 33%;
}

.w-60 {
  width: 60%;
}

.w-20 {
  width: 20%
}

.fit-content {
  width: fit-content;
}

.min-width-sm-auto {
  @media(max-width: 767px) {
    min-width: auto !important;
  }
}

/* Item Sizing */
/* NOTE: Parent needs display: flex */
.flex-half {
  float: left;
  width: 48%;
}

.flex-third {
  float: left;
  width: 32%;
}

.flex-full {
  float: left;
  width: 100%;
}
.overflowY-scroll {
  overflow-y: scroll;
}

.overflowX-scroll {
  overflow-x: scroll;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
}

/* Alignment */
.align-items-center {
  align-items: stretch;
}

.v-align-top {
  vertical-align: top !important;
}

.center-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.center-horizontal {
  justify-content: center;
  display: flex;
}

.center-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


/* Cursor */
.cursor-default {
  cursor: default !important;
}


/* Misc. Styles */
.link {
  cursor: pointer;

  &:hover {
    color: $brand !important;
  }
}

//TODO: Temporary fix until all selectors are referencing single component
.business-selector,
.audience-selector {
  input[type=text] {
    border: 1.5px solid transparent !important;
  }
}

input[type=text]:not(.MuiInputBase-input) {
  padding: 5px;
  border-radius: 3px;
  color: black;

  &:focus {
    outline: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hidden {
  visibility: hidden !important;
}

.visible {
  visibility: visible !important;
}

.border {
  border: $border-color;
}

.border-radius {
  border-radius: 4px !important;
}

.rounded {
  border-radius: 50px !important;
}

.table-list-img {
  height: 40px;
  width: 40px;
}

.box-shadow {
  box-shadow: 0 3px 6px rgba(0,0,0,0.09), 0 3px 6px rgba(0,0,0,0.09);
}

.toolbar {
  background-color: white;
  border: $border-color;
  border-radius: 4px;

  .search {
    max-width: 300px;
  }

  .icon-tools,
  .icon-tools i {
    font-size: 24px;
  }
}

.font-weight-500 {
  font-weight: 500;
}

.text-x-small {
  font-size: x-small;
}

.text-large {
  font-size: large;
}

.text-x-large {
  font-size: x-large;
}

.margin-top-1 {
  margin-top: 1em;
}

.margin-right-1 {
  margin-right: 1em;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-content-start {
  align-content: flex-start;
}

.width-1 {
  width: 1em;
}

.width-2 {
  width: 2em;
}

.width-3 {
  width: 3em;
}

.width-4 {
  width: 4em;
}

.width-5 {
  width: 5em;
}

.width-40p {
  width: 75%;
}

.width-65p {
  width: 65%;
}

.width-100p {
  width: 100%;
}

.height-025 {
  height: 0.25em;
}

.height-1 {
  height: 1em;
}

.height-2 {
  height: 2em;
}

.border-radius-40 {
  border-radius: 40%;
}

.border-radius-50 {
  border-radius: 50%;
}

.border-thick {
  border-width: 0.3em;
}

.text-red {color: #f44336;}
.background-red {background-color: #f44336;}
.border-red {border-color: #f44336;}

.text-purple {color: #9C27B0;}
.background-purple {background-color: #9C27B0;}
.border-purple {border-color: #9C27B0;}

.text-indigo {color: #3F51B5;}
.background-indigo {background-color: #3F51B5;}
.border-indigo {border-color: #3F51B5;}

.text-teal {color: #009688;}
.background-teal {background-color: #009688;}
.border-teal {border-color: #009688;}

.background-orange {background-color: #FF9800;}
.border-orange {border-color: #FF9800;}

.text-brown {color: #795548;}
.background-brown {background-color: #795548;}
.border-brown {border-color: #795548;}

.text-grey {color: #9E9E9E;}
.background-grey {background-color: #9E9E9E;}
.border-grey {border-color: #9E9E9E;}

.text-blue-grey {color: #607D8B;}
.background-blue-grey {background-color: #607D8B;}
.border-blue-grey {border-color: #607D8B;}

.text-white {color: #fafafa;}
.background-white {background-color: #fafafa;}
.border-white {border-color: #fafafa;}

.text-black {color: #212121;}
.background-black {background-color: #212121;}
.border-black {border-color: #212121;}

.hidden-mobile {
  @media (max-width: 620px) {
    display: none;
  }
}


// grid cards
.grid-card {

  //medium
  @media (min-width: 768px) {
    //.col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  //large
  @media (min-width: 992px) {
    //.col-lg-3 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  //xl
  @media (min-width: 1200px){
    //.col-xl-3 {
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
  }

  //xxl
  @media (min-width: 1400px) {
    //.col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  //xxxl
  @media (min-width: 1880px) {
    //.col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  //xxxxl
  @media (min-width: 2100px) {
    //.col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.5%;
    flex: 0 0 16.5%;
    max-width: 16.5%;
  }

}
