$brand: #a044ff;
$default: rgb(132, 144, 149);
$boundary: #edeeef;

.mm-ps {
  position: relative;
  position: relative;
  display: inline-block;

  .mm-ps-dropdown-click {
    cursor: pointer;
  }

  .mm-ps-dropdown-content {
    background-color: white;
    padding: 5px;
    box-shadow: 0 0 10px 0 rgba(82, 63, 105, 0.15);
    position: absolute;
    min-width: 160px;
    max-width: 870px;
    max-height: 500px;
    z-index: 1;
    width: max-content;

    @media (max-width: 767px) {
      left: 0;
      transform: translate(-28%, 1%);
      height: 100vh;
      max-width: calc(100vw - 20px);
      max-height: calc(100vh + 100px);
    }
  }

  .preset-col {
    border-right: solid 1px $boundary;
  }
  .time-col {
    border-left: solid 1px $boundary;
  }
}

.submit-button {
  text-transform: capitalize;
  padding: 5px 10px;
  border: solid 1px $brand;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  background-color: $brand;
  color: white;
}
