@import '../mm-period-selector';

.mm-ps-with-presets {
  width: 500px;
}
.mm-ps-presets {
  max-height: 400px;
  float: left;
  width: 150px;
  border-top-right-radius: 0 !important;
  overflow: scroll;

  .mm-ps-preset {
    padding: 15px 0;
    font-size: 20px;
    border-bottom: solid 1px $boundary;
    cursor: pointer;
    transition: all 0.2s;

    &.active {
      background-color: rgba($brand, 0.5) !important;
      color: white;
    }
  }
  .mm-ps-preset:hover {
    background-color: $brand;
    color: white;
  }
}
