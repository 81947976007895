@import '../../public/sass/variables';

.multi-option-menu {
  .dropdown-menu.show {
  }

  .menu-options-list {
    list-style: none;
    min-height: 250px !important;
    max-height: 250px !important;
    overflow-y: auto !important;
  }
}
