@import 'variables';

.btn {
  transition-duration: .25s;
  border: none;
  padding: 10px 20px;
}
.btn-round {
  border-radius: 10px;
}

.btn-mm:hover{
  box-shadow: 0 5px 10px rgba(0,0,0,0.3);
}

.btn-drive {
  color: white;
  background-color: $drive;

  &:hover {
    color: white !important;
    background-color: darken($drive, 2);
  }
}


.btn-brand {
  background-color: $brand;
  color: white;

  &:hover {
    color: white !important;
    background-color: lighten($brand, 5);
  }
}

.submit-btn {
  background-color: $success !important;
  color: white !important;
  padding: 5px 20px !important;
  border: 1px solid $success !important;
}

.btn-success-hollow {
  color: $success !important;
  background-color: transparent !important;
  border: 1px solid $success !important;

  &:hover {
    color: white !important;
    background-color: $success !important;
    border: 1px solid $success !important;
  }
}

.btn-hollow {
  background: transparent;
  border: solid 1px $gray !important;
  color: $gray !important;
  transition: all .03s;
  padding: 5px 20px !important;

  &:hover{
    background-color: gray !important;
    color: black !important;
  }
}

.btn-hollow.disabled {
  color: gray !important;
  border: solid 1px lightgray !important;
}

.btn-badge {
  font-size: 1em;
  background-color: rgba($brand, 0.33);
  color: $brand !important;
  padding: 2px 10px !important;
  font-weight: bold;

  &.round {
    border-radius: 10px;
  }

  &.hover:hover {
    color: white !important;
    background-color: lighten($brand, 5);
  }

  &.success {
    background-color: rgba($success, 0.33);
    color: $success !important;
    &.hover:hover {
      color: white !important;
      background-color: $success;
    }
  }

  &.warning {
    background-color: rgba($warning, 0.33);
    color: $warning !important;
    &.hover:hover {
      color: white !important;
      background-color: $warning;
    }
  }

  &.danger {
    background-color: rgba($danger, 0.33);
    color: $danger !important;
    &.hover:hover {
      color: white !important;
      background-color: $danger;
    }
  }

  &.info {
    background-color: rgba($info, 0.33);
    color: $info !important;
    &.hover:hover {
      color: white !important;
      background-color: $info;
    }
  }

  &.drive {
    background-color: rgba($drive, 0.33);
    color: $drive !important;
    &.hover:hover {
      color: white !important;
      background-color: $drive;
    }
  }

  &.disabled {
    opacity: 66%;
  }
}

//just for login
.btn-mm{
  padding: 10px;
  margin: 40px 0 20px 0;
  border-radius: 40px !important;
  width: 100%;
  background: linear-gradient(to right, $brand, #6B3095);
  box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
  color: white !important;
}