@import '../../../../../public/sass/variables';

.ribbon-target {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 15px;
  position: absolute;
  z-index: 1;
  background-color: $success !important;
  -webkit-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border-bottom-right-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
  right: 30px;
  top: -2px;
}
