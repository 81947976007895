.facebook-login-button {
  background: #3b5998;
  border-color: #4264aa #344e86 #344e86 #4264aa;
  color: white;

  &:hover {
    background: darken(#3b5998, 10%);
    color: white;
  }

  i {
    color: white;
    display: inline;
  }
}

.login-aside {
  display: none;
}

.login.login-1 .login-aside .aside-img {
  min-height: 450px;
}
@media (min-width: 992px) {
  .login-aside {
    display: flex;
    width: 100%;
    max-width: 700px;
  }
  .login.login-1 .login-content {
    width: 100%;
    max-width: 500px;
  }
  .login.login-1 .login-content .login-form {
    width: 100%;
    max-width: 450px;
  }
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 450px;
  }
}
@media (max-width: 991.98px) {
  .login.login-1 .login-content .login-form {
    width: 100%;
    max-width: 400px;
  }
}
@media (max-width: 575.98px) {
  .login.login-1 .aside-img {
    min-height: 300px !important;
    background-size: 400px;
  }
  .login.login-1 .login-content .login-form {
    width: 100%;
    max-width: 100%;
  }
}
