.bordered {
  border: 1.5px solid rgba(100, 108, 154, 0.31) !important;
  border-radius: 4px !important;
}

@media (max-width: 768px) {
  .card-body {
    padding: 5px !important;
  }
}

.content-card-head-note {
  padding: 10px;
  background-color: #dee6ef;
}
