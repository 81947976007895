@import '../mm-period-selector';

.mm-ps-time {
  display: inline;
  max-height: 400px;
}

.time-selector {
  border-bottom: 1px solid $boundary;
}

.time-selector:last-child {
  border-bottom: none;
}
