@import '../../../public/sass/variables';

.base-card {
  border-radius: 4px !important;
  border: 1.5px solid rgba(100, 108, 154, 0.31) !important;
  background-color: white;
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  white-space: normal;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.09), 0 3px 6px rgba(0, 0, 0, 0.09);
  }

  h1 i,
  h1 i:hover {
    color: $gray !important;
  }

  h3 {
    color: $gray2;
  }

  i.selection {
    position: absolute;
    right: 0;
    top: 0;
    margin: 15px;
    font-size: 20px;
    color: #b5b5c3;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: #b5b5c3;
    }
  }

  &.selected,
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.09), 0 3px 6px rgba(0, 0, 0, 0.09);
  }

  &.selected {
    border: solid 1.5px $success !important;

    i.selection {
      color: $success;
    }
  }
}
