.laptop {
  width: 100%;
  max-width: 770px;
  height: 100%;
  max-height: 515px;
  background: #f0f2f7;
  border: 19px solid black;
  border-radius: 10px 10px 0 0;
  position: absolute;

  @media (max-width: 1275px) {
    display: none;
  }

  @media (max-width: 1459px) {
    transform: scale(0.75, 0.75) translate(-44%, -4%);
  }

  @media (min-width: 1460px) {
    transform: scale(0.85, 0.85) translate(-25%, -4%);
  }

  @media (min-width: 1550px) {
    transform: scale(0.9, 0.9) translate(-17%, 5%);
  }

  @media (min-width: 1700px) {
    transform: scale(1, 1) translate(-8%, 5%);
  }

  @media (min-width: 1800px) {
    transform: scale(1.1, 1.1) translate(0%, 6%);
  }

  @media (min-width: 2000px) {
    transform: scale(1.2, 1.2) translate(10%, 7%);
  }

  & > div {
    position: absolute;
  }

  .btm {
    width: 100%;
    min-width: 825px;
    left: -6%;
    height: 20px;
    bottom: -32px;
    border-radius: 0 0 20px 20px;
    background: #383838;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      width: 52px;
      height: 5px;
      left: 50%;
      top: 0;
      margin-left: -21px;
      border-radius: 0 0 5px 5px;
      background: #676a70;
    }
  }
}
